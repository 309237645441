import { Component } from '@angular/core';
import { ReplaceableComponentsService } from '@abp/ng.core'; // imported ReplaceableComponentsService
import { eThemeBasicComponents } from '@abp/ng.theme.basic'; // imported eThemeBasicComponents enum for component keys
import { NgzorroLayoutComponent } from './ngzorro-layout/ngzorro-layout.component'; // imported MyApplicationLayoutComponent
import { NgzorroSiderMenuComponent } from './ngzorro-sider-menu/ngzorro-sider-menu.component'; // imported MyApplicationLayoutComponent
import { NgzorroTopMenuComponent } from './ngzorro-top-menu/ngzorro-top-menu.component';
import { NgzorroLanguagesComponent } from './ngzorro-languages/ngzorro-languages.component';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent {
    constructor(
      private replaceableComponents: ReplaceableComponentsService, // injected the service
    ) {
      this.replaceableComponents.add({
        component: NgzorroLayoutComponent,
        key: eThemeBasicComponents.ApplicationLayout,
      });
      this.replaceableComponents.add({
        component: NgzorroTopMenuComponent,
        key: eThemeBasicComponents.NavItems,
      });
    }
}

<ng-template #loginBtn>
  <a nz-button style="color: white;" nzType="link" (click)="navigateToLogin()">{{
    'AbpAccount::Login' | abpLocalization
    }}</a>
</ng-template>
<ng-template #titleTpl>
  <small *ngIf="(selectedTenant$ | async)?.name as tenantName"><i>{{ tenantName }}</i>\</small>
  <strong>{{ (currentUser$ | async)?.userName }}</strong>
</ng-template>

<div *ngIf="(currentUser$ | async)?.isAuthenticated; else loginBtn">
  <ul class="topmenu" nz-menu nzMode="horizontal">
    <li class="topmenuitem" nz-submenu [nzTitle]="titleTpl">
      <ul>
        <li (click)="navigateToManageProfile()" nz-menu-item>{{
          'AbpAccount::ManageYourProfile' | abpLocalization }}</li>
        <li (click)="logout()" nz-menu-item>{{
          'AbpUi::Logout' | abpLocalization }}</li>
      </ul>
  </ul>
</div>

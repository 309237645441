import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'home',
        pathMatch: 'full',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'clyj',
        loadChildren: () => import('./clyj/clyj.module').then(m => m.ClyjModule),
    },
    {
        path: 'account',
        loadChildren: () => import('@abp/ng.account').then(m => m.AccountModule.forLazy()),
    },
    {
        path: 'identity',
        loadChildren: () => import('@abp/ng.identity').then(m => m.IdentityModule.forLazy()),
    },
    {
        path: 'tenant-management',
        loadChildren: () =>
            import('@abp/ng.tenant-management').then(m => m.TenantManagementModule.forLazy()),
    },
    {
        path: 'setting-management',
        loadChildren: () =>
            import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule { }

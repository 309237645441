import { ConfigStateService, CurrentUserDto, eLayoutType, SubscriptionService } from '@abp/ng.core';
import { eThemeBasicComponents } from '@abp/ng.theme.basic';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-ngzorro-layout',
  templateUrl: './ngzorro-layout.component.html',
  styleUrls: ['./ngzorro-layout.component.scss']
})
export class NgzorroLayoutComponent  implements AfterViewInit, OnDestroy {
  constructor(
    private configState: ConfigStateService,
    private subscription: SubscriptionService
  ) { }

    // required for dynamic component
    static type = eLayoutType.application;
    currentUser$: Observable<CurrentUserDto> = this.configState.getOne$('currentUser');

    isCollapsed = true;

    smallScreen: boolean; // do not set true or false

    logoComponentKey = eThemeBasicComponents.Logo;

    routesComponentKey = eThemeBasicComponents.Routes;

    navItemsComponentKey = eThemeBasicComponents.NavItems;


    private checkWindowWidth() {
      setTimeout(() => {
        if (window.innerWidth < 992) {
          if (this.smallScreen === false) {
            this.isCollapsed = false;
            setTimeout(() => {
              this.isCollapsed = true;
            }, 100);
          }
          this.smallScreen = true;
        } else {
          this.smallScreen = false;
        }
      }, 0);
    }

    ngAfterViewInit() {
      this.checkWindowWidth();

      const resize$ = fromEvent(window, 'resize').pipe(debounceTime(150));
      this.subscription.addOne(resize$, () => this.checkWindowWidth());
    }

    ngOnDestroy() {}

}


<!-- <ul nz-menu nzMode="inline">

  <ng-container *ngFor="let route of routesService.visible$ | async; trackBy: trackByFn"
    [ngTemplateOutlet]="isDropdown(route) ? dropdownLink : defaultLink"
    [ngTemplateOutletContext]="{ $implicit: route }">
  </ng-container>

  <ng-template #defaultLink let-route>
    <li nz-menu-item [routerLink]="[route.path]">
      <span>{{route.name | abpLocalization}}</span>
    </li>
  </ng-template>

  <ng-template #dropdownLink let-route>
    <li nz-submenu [nzTitle]="route.name | abpLocalization">
    </li>
    <ul>
      <ng-container *ngTemplateOutlet="childrenLink; context: { $implicit: route}"></ng-container>
    </ul>
  </ng-template>
  <ng-template #childrenLink let-route>
    <ng-container *ngFor="let child of route.children">
      <li *ngIf="!child.children?.length" [routerLink]="[child.path]" nz-menu-item>
        <span>{{child.name | abpLocalization}}</span>
      </li>
      <li *ngIf="child.children?.length" nz-submenu [nzTitle]="child.name | abpLocalization">
        <ul>
          <ng-container *ngTemplateOutlet="childrenLink; context: { $implicit: child}"></ng-container>
        </ul>
      </li>
    </ng-container>
  </ng-template>

</ul> -->
<ul nz-menu nzMode="inline">
  <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: routesService.visible}"></ng-container>
  <ng-template #menuTpl let-menus>
    <ng-container *ngFor="let menu of menus">
      <li *ngIf="!menu.children?.length" [routerLink]="[menu.path]" [nzPaddingLeft]="menu| navPaddingChange"
        nz-menu-item>
        <i nz-icon nzType="alert"></i>
        <span>{{ menu.name | abpLocalization }}</span>
      </li>
      <li *ngIf="menu.children?.length" nz-submenu  [nzPaddingLeft]="menu | navPaddingChange" [nzOpen]="menu.open"
        [nzTitle]="menu.name | abpLocalization" [nzIcon]="menu.icon" [nzDisabled]="menu.disabled">
        <ul>
          <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"></ng-container>
        </ul>
      </li>
    </ng-container>
  </ng-template>
</ul>

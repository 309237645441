import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgzorroLayoutComponent } from './ngzorro-layout/ngzorro-layout.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NgzorroSiderMenuComponent } from './ngzorro-sider-menu/ngzorro-sider-menu.component';
import { NavPaddingChangePipe } from './shared/pipes/nav-padding-change.pipe';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { AccountBookFill, AlertFill, AlertOutline } from '@ant-design/icons-angular/icons';
import { NgzorroTopMenuComponent } from './ngzorro-top-menu/ngzorro-top-menu.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgzorroCurrentUserComponent } from './ngzorro-current-user/ngzorro-current-user.component';
import { NgzorroLanguagesComponent } from './ngzorro-languages/ngzorro-languages.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
const icons: IconDefinition[] = [AccountBookFill, AlertOutline, AlertFill];
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzButtonModule,
    NzLayoutModule,
    NzMenuModule,
    NzDropDownModule,
    NzSelectModule,
    NzGridModule,
    NzIconModule.forRoot(icons),
    NzBreadCrumbModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    NgxEchartsModule.forRoot({
      echarts,
    }),
    ThemeSharedModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    NgxsModule.forRoot(),
    ThemeBasicModule.forRoot(),
  ],
  declarations: [AppComponent, NgzorroLayoutComponent, NgzorroSiderMenuComponent, NavPaddingChangePipe, NgzorroTopMenuComponent, NgzorroCurrentUserComponent, NgzorroLanguagesComponent],
  providers: [APP_ROUTE_PROVIDER],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { ConfigStateService, LanguageInfo, SessionStateService } from '@abp/ng.core';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import snq from 'snq';

@Component({
  selector: 'app-ngzorro-languages',
  templateUrl: './ngzorro-languages.component.html',
  styleUrls: ['./ngzorro-languages.component.scss']
})
export class NgzorroLanguagesComponent {

  get smallScreen(): boolean {
    return window.innerWidth < 992;
  }

  languages: LanguageInfo[] = this.configState.getDeep('localization.languages');

  selectLanguageCultureName: string = this.sessionState.getLanguage();
  // get defaultLanguage$(): Observable<string> {
  //   return this.languages$.pipe(
  //     map(
  //       languages =>
  //         snq(
  //           () => languages.find(lang => lang.cultureName === this.selectLanguageCultureName).displayName,
  //         ),
  //       '',
  //     ),
  //   );
  // }
  // tslint:disable-next-line:no-any
  // compareFn(o1: any, o2: any) {
  //   let b = o1 === o2;
  //   return b;
  // };

  // get dropdownLanguages$(): Observable<LanguageInfo[]> {
  //   var a = this.languages$.pipe(
  //     map(
  //       languages =>
  //         snq(() => languages.filter(lang => lang.cultureName !== this.selectLanguageCultureName)),
  //       [],
  //     ),
  //   );

  //   var ccc = this.configState.getDeep("localization.languages");
  //   var r = ccc.filter(p=>p.cultureName !== this.selectLanguageCultureName);
  //   a.toPromise().then(data =>{
  //     var ccc = data;
  //   })
  //   return a;
  // }

  // get dropdownLanguages(): LanguageInfo[] {
  //   var ccc = this.configState.getDeep("localization.languages");
  //   var r = ccc.filter(p=>p.cultureName !== this.selectLanguageCultureName);
  //   return r;
  // }

  //   get selectedLangCulture(): string {
  //     this.selectLanguageCultureName = this.sessionState.getLanguage();
  //     return this.sessionState.getLanguage();
  //   }
  //   set selectedLangCulture(name: string) {
  //     this.selectLanguageCultureName = name;
  // }
  constructor(private sessionState: SessionStateService, private configState: ConfigStateService) { }

  // onChangeLang(cultureName: string) {
  //   this.sessionState.setLanguage(cultureName);
  // }
  onChangeLang() {
    this.sessionState.setLanguage(this.selectLanguageCultureName);
  }
}

<nz-layout>
  <nz-header style="height: 130px;padding: 0px;">
    <div class="wsc-Nav">
      <div class="wsc-NavCon clearfix">
        <div class="left-block clearfix">
          <div class="wsc-logo">
            <h1 style="margin:0px;"><a href="/" title=""><img src="/assets/images/logo.png" alt="农作物品种及智慧种植服务平台"></a>
            </h1>
          </div>
          <div class="wsc-title">
            <div class="wsc-tit">农作物品种及智慧种植服务平台</div>
            <div class="wsc-ftit">
              Henan Province Service Platform for crop variety and Technical Information
            </div>
            <div class="wsc-msg">
              <img src="/assets/images/wsc-ftit.png" alt="河南省农业科学院农业信息技术研究所">
            </div>
          </div>
        </div>
        <div class="right-block">
          <div class="wsc-msg">
            <img src="/assets/images/wsc-title.png" alt="藏粮于地 藏粮于技">
          </div>
        </div>
      </div>
      <div class="wsc-headerNav clearfix">
        <div class="wsc-leftNav left-block clearfix">
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/home']" id="m188"><span></span>首页</a>
          </div>
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/clyj/pinzhong']"><span></span>品种数据</a>
          </div>
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/clyj/qixiang']" id="m189"><span></span>气象数据</a>
          </div>
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/clyj/turang']" id="m1829"><span></span>土壤数据</a>
          </div>
          <!-- <div class="hn-item">
            <a href='/clyj/pinzhong' id="m189"><span></span>农技知识</a>
          </div> -->
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/clyj/analysis']" id="m1291"><span></span>数据分析</a>
          </div>
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/clyj/pinzhongjuece']" id="m1291"><span></span>应急指导</a>
          </div>
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/clyj/pinzhongplan']" id="m1291"><span></span>品种决策</a>
          </div>
          <div class="hn-item" routerLinkActive='active'>
            <a [routerLink]="['/clyj/aboutus']" id="m1891"><span></span>关于我们</a>
          </div>
        </div>
        <div class="wsc-rightNav right-block clearfix">
          <abp-nav-items *abpReplaceableTemplate="{
            componentKey: navItemsComponentKey
          }"></abp-nav-items>
        </div>
      </div>
    </div>
  </nz-header>
  <nz-content class="outer-content">

    <!-- <nav class="navbar navbar-expand-lg" id="main-navbar" style="min-height: 4rem">
      <div class="container">
        <div class="topLogo">
           <img class="topLogoimg" src="/assets/images/2.png" />
        </div>
        <abp-logo *abpReplaceableTemplate="{ componentKey: logoComponentKey }"></abp-logo>
        <abp-nav-items *abpReplaceableTemplate="{
        componentKey: navItemsComponentKey
      }"></abp-nav-items>
      </div>
    </nav> -->

    <!-- <nz-breadcrumb>
      <nz-breadcrumb-item>Home</nz-breadcrumb-item>
      <nz-breadcrumb-item>List</nz-breadcrumb-item>
      <nz-breadcrumb-item>App</nz-breadcrumb-item>
    </nz-breadcrumb> -->
    <nz-layout class="inner-layout">
      <nz-sider *ngIf="(currentUser$ | async)?.isAuthenticated" nzWidth="200px" nzTheme="light">
        <app-ngzorro-sider-menu></app-ngzorro-sider-menu>
      </nz-sider>
      <nz-content class="inner-content">
        <router-outlet></router-outlet>
      </nz-content>
    </nz-layout>
    <nz-footer>
      <div class="wsc-bottom">
        <div class="container">
          <div class="wb-text">
            <em>友情链接：</em>
            <a href="" target="_blank">河南省农业科学院</a>
            <a href="" target="_blank">河南省农业科学院农业信息技术研究所</a>
          </div>
          <div class="wb-copyright">
            <span>版权所有：河南省农业科学院农业信息技术研究所</span>
          </div>
        </div>
      </div>



    </nz-footer>
  </nz-content>
</nz-layout>

import { NavItem, NavItemsService } from '@abp/ng.theme.shared';
import { Component, OnInit, TrackByFunction } from '@angular/core';

@Component({
  selector: 'app-ngzorro-top-menu',
  templateUrl: './ngzorro-top-menu.component.html',
  styleUrls: ['./ngzorro-top-menu.component.scss']
})
export class NgzorroTopMenuComponent implements OnInit {
  trackByFn: TrackByFunction<NavItem> = (_, element) => element.id;
  constructor(public readonly navItems: NavItemsService) {}
  ngOnInit(): void {
  }

}

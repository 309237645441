import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'navPaddingChange'
})
export class NavPaddingChangePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    var paddingLeft = 0;
    if (value.parent) {
      paddingLeft = paddingLeft + 48
      if (value.parent.parent) {
        paddingLeft = paddingLeft + 24
        if (value.parent.parent.parent) {
          paddingLeft = paddingLeft + 24
          if (value.parent.parent.parent.parent) {
            paddingLeft = paddingLeft + 24
          }
        }
      }
    }
    return paddingLeft;
  }

}
